define('idcta-v2/tokenRefresh', [
    'idcta-v2/id-config',
    'idcta-v2/idCookie',
    'idcta-v2/apiUtils',
    'idcta-v2/logger',
    'idcta-v2/analytics',
], function(idConfig, idCookie, apiUtils, logger, analytics) {
    var RETRY_AFTER_SHORT = 15000; //15s
    var RETRY_AFTER_LONG = 3600000; //1h

    var pendingTokenRefreshPromises = {};

    function refreshAccessToken(forceRefresh, hostname) {
        var idCookieInstance = idCookie.getInstance();

        try {
            if (!idCookieInstance.hasCookie()) {
                var message = 'ckns_id cookie is missing, invalid or expired';
                return apiUtils.Promise.reject(new TokenRefreshError(message));
            }

            if (idCookieInstance.isHybridApp()) {
                var message = 'hybrid app detected';
                return apiUtils.Promise.reject(new TokenRefreshError(message));
            }

            var errorInCookie = idCookieInstance.getError();

            if (
                errorInCookie &&
                apiUtils.timestampInFuture(errorInCookie.retryAfter)
            ) {
                var message = 'retry period has not passed';
                return apiUtils.Promise.reject(
                    new TokenRefreshError(message, errorInCookie.retryAfter)
                );
            }

            if (
                forceRefresh !== true &&
                !idCookieInstance.hasAccessTokenExpired()
            ) {
                return apiUtils.Promise.resolve();
            }

            var refreshTokenViaFetch = function(src) {
                var options = {
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                return fetch(src, options);
            };

            if (typeof pendingTokenRefreshPromises[hostname] === 'undefined') {
                pendingTokenRefreshPromises[
                    hostname
                ] = idConfig.getConfigAsync().then(function(config) {
                    var src = config.tokenRefresh_url;
                    src = hostname
                        ? apiUtils.alignTldWithHostname(src, hostname)
                        : src;
                    return refreshTokenViaFetch(src);
                });
            }

            return pendingTokenRefreshPromises[hostname]
                .then(function() {
                    pendingTokenRefreshPromises[hostname] = undefined;
                })
                .catch(function(error) {
                    pendingTokenRefreshPromises[hostname] = undefined;
                    return handleTokenRefreshError(error);
                });
        } catch (error) {
            logger.logCaughtError(error);
        }
    }

    var handleTokenRefreshError = function(error) {
        return idConfig.getConfigAsync().then(function(config) {
            var retryAfter =
                new Date().getTime() +
                (config.tokenRefresh ? RETRY_AFTER_SHORT : RETRY_AFTER_LONG);

            error.retryAfter = retryAfter;

            idCookie.getInstance().setCknsIdCookieProperty('ec', error);
            throw error;
        });
    };

    function TokenRefreshError(message, retryAfter) {
        this.name = 'TokenRefreshError';
        this.message = message;
        this.retryAfter = retryAfter;
    }
    TokenRefreshError.prototype = Error.prototype;

    var exports = {};

    exports.refreshAccessToken = refreshAccessToken;

    analytics.wrapModuleWithAnalytics(exports, 'tokenRefresh');

    return exports;
});

