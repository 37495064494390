/**
 * @overview Module 'id-config' provides runtime configuration values
 * such as URLs for sign-in, register, and token refresh actions, as well
 * as translations for parts of the UI.
 * @namespace idcta
 */
define('idcta-v2/id-config', [
    'idcta-v2/config',
    'idcta-v2/apiUtils',
    'idcta-v2/analytics',
], function(config, apiUtils, analytics) {
    function getTldFromHostname() {
        var hostnameParts = window.location.host.split('.');
        if (
            hostnameParts[hostnameParts.length - 2] === 'co' &&
            hostnameParts[hostnameParts.length - 1] === 'uk'
        ) {
            return hostnameParts[hostnameParts.length - 3] + '.co.uk';
        } else {
            return (
                hostnameParts[hostnameParts.length - 2] +
                '.' +
                hostnameParts[hostnameParts.length - 1]
            );
        }
    }

    var tld = getTldFromHostname();

    function createConfig(productName) {
        var queryParameters = {
            context: productName.context,
            userOrigin: productName.userOrigin,
        };

        return {
            get announce_url() {
                return apiUtils.alignTldWithHostname(config.announce_url);
            },
            get 'bbcid-v5'() {
                return config['bbcid-v5'];
            },
            get child_parent_linking_url() {
                return config.child_parent_linking_url;
            },
            get 'id-availability'() {
                return config['id-availability'];
            },
            get identity() {
                return {
                    cookieAgeDays: config.identity.cookieAgeDays,
                    accessTokenCookieName:
                        config.identity.accessTokenCookieName,
                    idSignedInCookieName: config.identity.idSignedInCookieName,
                };
            },
            get identityTokenExchangeUrl() {
                return apiUtils.alignTldWithHostname(
                    config.identityTokenExchangeUrl
                );
            },
            get privacy_settings_url() {
                return config.privacy_settings_url;
            },
            get profiles_create_url() {
                return config.profiles.create;
            },
            get profiles_list_url() {
                return config.profiles.list;
            },
            get ptrt() {
                return window.location.href;
            },
            get register_url() {
                return apiUtils.appendQueryParameters(
                    apiUtils.alignTldWithHostname(config.register_url),
                    queryParameters
                );
            },
            get settings_url() {
                return apiUtils.appendQueryParameters(
                    config.settings_url,
                    queryParameters
                );
            },
            get signin_url() {
                return apiUtils.appendQueryParameters(
                    apiUtils.alignTldWithHostname(config.signin_url),
                    queryParameters
                );
            },
            get signout_url() {
                return apiUtils.appendQueryParameters(
                    config.signout_url,
                    queryParameters
                );
            },
            get status_url() {
                return apiUtils.appendQueryParameters(
                    config.status_url,
                    queryParameters
                );
            },
            get tld() {
                return tld;
            },
            get tokenRefresh() {
                return config.tokenRefresh || false;
            },
            get tokenRefresh_signout_url() {
                return apiUtils.alignTldWithHostname(
                    config.tokenRefresh_signout_url
                );
            },
            get tokenRefresh_url() {
                return apiUtils.alignTldWithHostname(config.tokenRefresh_url);
            },
            get translation_signedin() {
                return config.translations.statusbarSignedIn;
            },
            get translation_signedout() {
                return config.translations.statusbarSignedOut;
            },
        };
    }

    var exports = {};

    var configPromise = apiUtils.getProductNameAsync().then(createConfig);

    /**
     * Retrieve the config asynchronously
     * @returns promise
     */
    exports.getConfigAsync = function() {
        return configPromise;
    };

    /**
     * Retrieve the current domain.
     * @returns string
     */
    exports.getDomain = function() {
        return tld;
    };

    /**
     * Retrieve the identity configuration,
     * containing cookie names and age.
     * @returns object
     */
    exports.getIdentity = function() {
        return config.identity;
    };

    exports.getFeatureDecisions = function() {
        return config.features;
    };

    exports.getUsiDestinationWhitelist = function() {
        return config.usiDestinationWhitelist;
    };

    exports.getExperiments = function() {
        return config.experiments;
    };

    analytics.wrapModuleWithAnalytics(exports, 'id-config');

    return exports;
});

