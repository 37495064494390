define('idcta-v2/analytics', ['idcta-v2/config'], function(config) {
    var exports = {};
    var loggingEnabled = true;

    var allowDeepLog = true;

    var blockListedMethods = {
        'idcta-1': {
            addUserOrigin: true,
            getCookieInstance: true,
            getIdFromCookie: true,
            getUserDetailsFromCookie: true,
            hasCookie: true,
            initiateTokenRefresh: true,
            Cta: true,
            modifyPtrt: true,
        },
        'id-config': {
            getConfigAsync: true,
        },
        idCookie: {
            getInstance: true,
        },
        statusbar: {
            Statusbar: true,
        },
    };

    function setLoggingEnabled(enabled) {
        loggingEnabled = enabled;
    }

    function withAnalytics(moduleName, methodName, method) {
        return function() {
            var blocked =
                blockListedMethods[moduleName] &&
                blockListedMethods[moduleName][methodName];

            if (allowDeepLog && loggingEnabled && !blocked) {
                document.dispatchEvent(
                    new CustomEvent('bbc-user-event', {
                        detail: {
                            container: 'id_cta_client_side',
                            label: methodName,
                            source: moduleName,
                        },
                    })
                );
            }

            allowDeepLog = false;
            var args = [].slice.apply(arguments);
            var returnValue = method.apply(this, args);
            allowDeepLog = true;
            return returnValue;
        };
    }

    function wrapModuleWithAnalytics(module, moduleName) {
        if (config && config.features.clientAnalytics && Object.keys) {
            Object.keys(module).forEach(function(key) {
                if (typeof module[key] === 'function') {
                    module[key] = withAnalytics(moduleName, key, module[key]);
                }
            });
        }
    }

    exports.setLoggingEnabled = setLoggingEnabled;
    exports.withAnalytics = withAnalytics;
    exports.wrapModuleWithAnalytics = wrapModuleWithAnalytics;

    return exports;
});

