define('idcta-v2/logger', ['idcta-v2/analytics'], function(analytics) {
    var exports = {};

    exports.logCaughtError = function(err) {
        if (window.console && err.message) {
            console.log(err);
        }
    };

    exports.logMessage = function(message) {
        var debugCookieName = 'ckns_debugtoken';
        var debugCookieMatch = document.cookie.match(
            new RegExp(debugCookieName + '=([^;]+)')
        );
        if (debugCookieMatch) {
            window.tokenRefeshLog = window.tokenRefeshLog || [];
            window.tokenRefeshLog.push(message);
        }
    };

    analytics.wrapModuleWithAnalytics(exports, 'logger');

    return exports;
});

